import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import * as moment from 'moment'
import 'moment/locale/es'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InvoicesController from '../../../services/invoices'
import { Loading, Empty } from '../../../components/splash'
import AccountStore from '../../../store/user'
export default class Users extends Component {
  constructor(props) {
    super(props)
    this.state = {
      users: null,
      page: 1,
      search: '',
      filtered: [],
      sortedKey: '',
      loading: false,
      redirect: false,
      resetSearch: false,
      roles: {
        0: 'Administrador',
        1: 'Supervisor',
        2: 'Técnico/Perito',
        3: 'Ajustador',
        4: 'Coordinador'
      }
    }
  }
  componentDidMount() {
    this.loadUsers(1)
    AccountStore.on('update_company', () => this.setState({ redirect: true }))
  }
  componentWillUnmount() {
    AccountStore.removeListener('update_company', () => this.setState({ redirect: true }))
  }
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value })
  }
  toggleSidebar = () => {
    this.props.sceneRef.current.classList.toggle("open")
  }
  loadUsers = async (page, query = null) => {
    this.setState({ loading: true })
    try {
      const users = await InvoicesController.getAll(page, query)
      this.setState({
        loading: false,
        page: users.result.current_page,
        maxPages: users.result.last_page,
        users: users.result.data
      })
    } catch (e) {
      this.setState({ loading: false })
    }
  }
  handleSearch = async (e) => {
    e.preventDefault()
    this.setState({ searching: true })
    try {
      let query = this.state.search
      await this.loadUsers(1, query)
      this.setState({ searching: false })
    } catch (e) {
      this.setState({ searching: false })
    }
  }
  handleResetSearch = async () => {
    this.setState({ search: '', resetSearch: true })
    try {
      await this.loadUsers(1, null)
      this.setState({ resetSearch: false })
    } catch (e) {
      this.setState({ resetSearch: false })
    }
  }
  handleSort = (key) => {
    const { search, filtered, users, sortedKey } = this.state
    const list = search.length > 0 ? filtered : (users ? users : [])
    if (list.length > 0) {
      let sorted = list.sort((a, b) => {
        if (a[key].toLowerCase() < b[key].toLowerCase()) return -1;
        if (a[key].toLowerCase() > b[key].toLowerCase()) return 1;
        return 0
      })
      let newKey = key
      if (sortedKey === key) {
        newKey = ''
        sorted = sorted.reverse()
      }
      this.setState({ filtered: sorted, sortedKey: newKey })
    } else {
      return
    }
  }
  handleNextPage = () => {
    const { page, maxPages } = this.state
    if (page < maxPages) {
      this.loadUsers(page + 1)
    }
  }
  handlePrevPage = () => {
    const { page } = this.state
    if (page > 1) {
      this.loadUsers(page - 1)
    }
  }
  render() {
    const { users, resetSearch, loading, search, searching, redirect, roles } = this.state
    const list = users ? users : []
    if (redirect) {
      return <Redirect to="/" />
    }
    return (
      <div className="scene contact">
        {list ?
          <div className="container">
            <div className="content">
              <div className="header top">
                <button className="btn toggle" onClick={this.toggleSidebar}>
                  <FontAwesomeIcon icon="bars" />
                </button>
                <div className="title-w-search">
                  <form onSubmit={this.handleSearch} className="searchbar">
                    <input
                      id="search"
                      type="text"
                      placeholder="Buscar por rfc,nombre"
                      onChange={this.handleChange}
                      value={search}
                    />
                    <button className="btn success" type="submit">
                      {searching ?
                        <FontAwesomeIcon icon="spinner" spin />
                        :
                        <FontAwesomeIcon icon="search" />
                      }
                    </button>
                  </form>
                  <button type="button" onClick={this.handleResetSearch} className="btn danger mL-6">
                    {resetSearch ?
                      <FontAwesomeIcon icon="spinner" spin />
                      :
                      <FontAwesomeIcon icon="sync" />
                    }
                  </button>
                  <h2 className="uppercase title">{"Facturación"}</h2>
                </div>
              </div>
              <div className="subheader">
                {!loading && list.length ?
                  <div className="flex pagination">
                    <button onClick={this.handlePrevPage} className="btn">
                      <FontAwesomeIcon icon="chevron-left" />
                    </button>
                    <div className="pages">
                      {`${this.state.page} / ${this.state.maxPages}`}
                    </div>
                    <button onClick={this.handleNextPage} className="btn">
                      <FontAwesomeIcon icon="chevron-right" />
                    </button>
                  </div>
                  : null}
              </div>
              <div className="flexbox">
                {!loading && list.length ?
                  <table className="bordered">
                    <thead>
                      <tr>
                        <th>{"RFC"}</th>
                        <th>{"Tipo"}</th>
                        <th>{"Nombre"}</th>
                        <th>{"F. Alta"}</th>
                        <th><Link to={"/invoice/"} className="btn">{"Agregar"}</Link></th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((item, key) =>
                        <tr key={key}>
                          <td><Link to={"/invoice/" + item.id}>{item.rfc}</Link></td>
                          <td>
                            <Link to={"/invoice/" + item.id}>
                              {item.tipo === 'fisica' ?
                              <>Persona fisica</>
                              :
                              <>Persona moral</>
                              }
                            </Link>
                          </td>
                          <td>
                            <Link to={"/invoice/" + item.id}>
                              {item.tipo === 'fisica' ?
                              <>{item.nombre} {item.apellido1} {item.apellido2}</>
                              :
                              <>{item.moral_razon_social}</>
                              }
                            </Link>
                          </td>
                          <td className="uppercase"><Link to={"/invoice/" + item.id}>{moment(item.created_at).format('DD/MMM/YY')}</Link></td>
                          <td className="action">
                            <Link to={"/invoice/" + item.id} className="btn reference">{"Ver más"}</Link>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  : (!loading && search.length > 0 ?
                    <p style={{ textAlign: 'center' }}>No hay resultados para: {search}</p>
                    : null)}
              </div>
            </div>
          </div>
          : (loading ?
            <Loading />
            :
            <Empty title="valuaciones" toggleSidebar={this.toggleSidebar} uri="/usuario/" label="Agregar usuario" />
          )}
      </div>
    )
  }
}
