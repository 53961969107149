export const api = process.env.REACT_APP_API
export const avatarUrl = process.env.REACT_APP_AVATAR_URL
export const bdeorUrl = process.env.REACT_APP_BDEO_URL

export const apiAutocomCube = "https://pruebaservicios.autocomcube.mx/api" //qa

//export const pathFilesDocs = "http://127.0.0.1:8000/" //local files
export const pathFilesDocs = "https://qa-api.invarat.mx/storage/" //local files

export const backend = "https://api.invarat.mx/public"
export const InterventionSettings = {
  default: {details: {admin: true, adminLocation: true, client: true, car: true}, documents: {items: ['all']}, bodywork: {items: ['all'], totalValue: 130, difValue: 0}, cabin: {items: ['all']}, mechanics: {items: ['all']}},
  revision: {details: {admin: true, adminLocation: true, client: true, car: true}, documents: {items: ['all']}, bodywork: {items: ['all'], totalValue: 130, difValue: 0}, cabin: {items: ['all']}, mechanics: {items: ['all']}},
  peritaje: {details: {admin: true, adminLocation: false, client: false, car: false}, bodywork: {items: ['all'], totalValue: 130, difValue: 0}, cabin: {items: ['all']}, mechanics: {items: ['all']}},
  tomadanos: {details: {admin: false, adminLocation: false, client: false, car: false}, bodywork: false, cabin: false, mechanics: false, partsQuotationFiles: true, repairQuotationFiles: true, disabledAutocomplete: true},
  acondicionamiento: {bodywork: {items: ['all'], totalValue: 126, difValue: 0}, cabin: {items: ['all']}, mechanics: {items: ['all']}, witnessFiles: false, pricingQuotationFiles: false, partsQuotationFiles: true, repairQuotationFiles: true},
  clicar: { details: { admin: true, adminLocation: true, client: true, car: true }, interior: { items: ['all'], totalValue: 126, difValue: 0 }, exterior: { items: ['all'] }, documents: { items: ['all'] }}
}
export const DocumentsCatalog = {
  vehicle: ['Factura de Origen', 'Consecutivo de facturas', 'Facturas de cliente hacia Autocom', 'Pago de todas las tenencias', 'Baja de placas',
    'Llaves y duplicado', 'Verificación Vehicular', 'Manuales del propietario'],
  client: ['Identificación oficial Vigente', 'CURP o copia de acta de nacimiento', 'Comprobante de domicilio', 'Poder notarial', 'Acta Constitutiva',
    'Caratula del estado de cuenta']
}

export const workingHours = {
  start: '08:00',
  end: '19:59'
}
