import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'

const InterventionsTable = ({ interventions }) => (
  <table className="mB-25 small">
    <thead>
      <tr>
        <th>{"#"}</th>
        <th>{"Autor"}</th>
        <th>{"Responsable"}</th>
        <th>{"Auto Ajuste"}</th>
        <th>{"Estado"}</th>
        <th>{"Fecha"}</th>
        <th style={{ maxWidth: 50 }}></th>
      </tr>
    </thead>
    <tbody>
      {interventions.map((item, key) =>
        <tr key={key}>
          <td>{item.id}</td>
          <td>{item.author.name} {item.author.familyname}</td>
          <td>{item.responsable.name} {item.responsable.familyname}</td>
          <td>{item.proveedor_autoajuste}</td>
          <td>
            <span className={"tag " + (item.status === "Expirada" ? "danger" : (item.status === "Completada" ? "success" : "secondary"))}>
              {item.status}
            </span>
          </td>
          <td>{moment.tz(item.date, 'UTC').clone().format('DD/MM/YYYY h:mm A')}</td>
          <td style={{ maxWidth: 60 }}>
            <Link
              className={"btn " + (item.status === "Pendiente" ? "success" : "reference")}
              to={"/intervencion/revision/" + item.id}
            >
              {item.status === "Pendiente" ? "Comenzar" : "Ver más"}
            </Link>
          </td>
        </tr>
      )}
    </tbody>
  </table>
)

export default InterventionsTable
